// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/star.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/star-active.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".ArtistCard_card_-reFI{font-size:.37333rem;font-weight:500;width:7.62667rem;height:4.48rem;box-sizing:border-box;padding:.42667rem .42667rem 0 .42667rem;background:#212121;border-radius:.82667rem}.ArtistCard_card_-reFI.ArtistCard_fill_2XcHx{width:100%}.ArtistCard_card_-reFI .ArtistCard_cardHead_1BA2S{justify-content:space-between;margin-bottom:.42667rem}.ArtistCard_card_-reFI .ArtistCard_cardHead_1BA2S,.ArtistCard_card_-reFI .ArtistCard_cardHead_1BA2S .ArtistCard_cardInfo_1vVOD{display:flex;align-items:center}.ArtistCard_card_-reFI .ArtistCard_cardHead_1BA2S .ArtistCard_cardInfo_1vVOD .ArtistCard_avatar_bm-QD{width:.85333rem;height:.85333rem;border-radius:50%;margin-right:.21333rem}.ArtistCard_card_-reFI .ArtistCard_cardHead_1BA2S .ArtistCard_star_1c51i{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;width:.85333rem;height:.85333rem}.ArtistCard_card_-reFI .ArtistCard_cardHead_1BA2S .ArtistCard_star_1c51i.ArtistCard_active_2DwPx{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.ArtistCard_card_-reFI .ArtistCard_cardContent_24oOY{display:flex}.ArtistCard_card_-reFI .ArtistCard_cardContent_24oOY .ArtistCard_cardImg_1mcGn{width:2.13333rem;height:2.13333rem;box-shadow:0 .48rem .72rem 0 rgba(0,0,0,.5);border-radius:.29333rem;margin-right:.18667rem}.ArtistCard_card_-reFI .ArtistCard_cardContent_24oOY .ArtistCard_cardImg_1mcGn.ArtistCard_fill_2XcHx{width:2.34667rem;height:2.34667rem;border-radius:.34667rem}", ""]);
// Exports
exports.locals = {
	"card": "ArtistCard_card_-reFI",
	"fill": "ArtistCard_fill_2XcHx",
	"cardHead": "ArtistCard_cardHead_1BA2S",
	"cardInfo": "ArtistCard_cardInfo_1vVOD",
	"avatar": "ArtistCard_avatar_bm-QD",
	"star": "ArtistCard_star_1c51i",
	"active": "ArtistCard_active_2DwPx",
	"cardContent": "ArtistCard_cardContent_24oOY",
	"cardImg": "ArtistCard_cardImg_1mcGn"
};
module.exports = exports;
