// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index_wrap_1_dAe{padding-top:1.6rem}.index_wrap_1_dAe .index_back_kubHD{position:fixed;top:0;z-index:9999}.index_wrap_1_dAe .index_list_2tHCA{padding:0 .85333rem}.index_wrap_1_dAe .index_list_2tHCA .index_listItem_1EkHY{margin-bottom:.42667rem}", ""]);
// Exports
exports.locals = {
	"wrap": "index_wrap_1_dAe",
	"back": "index_back_kubHD",
	"list": "index_list_2tHCA",
	"listItem": "index_listItem_1EkHY"
};
module.exports = exports;
