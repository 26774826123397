<template>
  <div
    :class="[$style.card, { [$style.fill]: mode === 'fill' }]"
    @click="handle2Detail"
  >
    <div :class="$style.cardHead">
      <div :class="$style.cardInfo">
        <img :class="$style.avatar" :src="info.headimg" />
        <span>{{ info.name }}</span>
      </div>
      <i
        :class="[$style.star, { [$style.active]: collected }]"
        @click.stop="handleSwitchStar"
      ></i>
    </div>
    <div :class="$style.cardContent">
      <img
        @click.stop="handle2ClothDetail(img.id)"
        v-for="img in info.ddc_list"
        :class="[$style.cardImg, { [$style.fill]: mode === 'fill' }]"
        :key="img.id"
        :src="img.image_url"
      />
    </div>
  </div>
</template>

<script>
import { followArtist } from "@/api";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "", // fill，就是在列表中的样式，宽度占满100%，图片88px
    },
  },
  data() {
    return {
      collected: Number(this.info.is_followed) === 1,
    };
  },
  methods: {
    handleSwitchStar() {
      followArtist({ artist_id: this.info.id }).then(() => {
        this.collected = !this.collected;
        const msg = this.collected ? "已收藏" : "取消收藏";
        this.$toast(msg);
      });
    },
    handle2Detail() {
      this.$router.push({
        path: `/artist/detail/${this.info.id}`,
      });
    },
    handle2ClothDetail(id) {
      this.$router.push({
        path: `/cloth/detail/${id}`,
      });
    },
  },
};
</script>

<style lang="scss" module>
.card {
  font-size: 14px;
  font-weight: 500;
  width: 286px;
  height: 168px;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  background: #212121;
  border-radius: 31px;
  &.fill {
    width: 100%;
  }
  .cardHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .cardInfo {
      display: flex;
      align-items: center;
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .star {
      background-image: url("~@/assets/img/star.png");
      background-size: contain;
      width: 32px;
      height: 32px;
      &.active {
        background-image: url("~@/assets/img/star-active.png");
      }
    }
  }
  .cardContent {
    display: flex;
    .cardImg {
      width: 80px;
      height: 80px;
      box-shadow: 0px 18px 27px 0px rgba(0, 0, 0, 0.5);
      border-radius: 11px;
      margin-right: 7px;
      &.fill {
        width: 88px;
        height: 88px;
        border-radius: 13px;
      }
    }
  }
}
</style>
