<template>
  <div :class="$style.wrap">
    <van-search
      background="#212121"
      :class="$style.search"
      v-model="value"
      :placeholder="`请输入搜索的${placeholderText}`"
      :left-icon="require('@/assets/img/search.png')"
      @search="handleSearch"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholderText: {
      type: String,
      default: "内容",
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    handleSearch(val) {
      this.$emit("search", val);
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  padding: 0 32px;
  margin: 26px 0;
  .search {
    border-radius: 10px;
    height: 56px;
    color: #fff;
    :global {
      .van-search__content {
        background-color: #212121;
      }
      .van-icon {
        width: 24px;
        height: 24px;
      }
      .van-icon__image {
        width: 24px;
        height: 24px;
      }
      .van-field__left-icon {
        margin-right: 16px;
      }
      .van-field__control {
        &::placeholder {
          color: #8e8e95;
        }
        color: #fff;
      }
    }
  }
}
</style>
